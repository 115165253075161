import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import * as moment from "moment";
import { DateRangePicker } from "react-dates";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";

import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Layout from "../components/Layout";
import HTMLContent from "../components/Content";
import "../styles/reservation.scss";

const pricePerNight = 220;
const priceBreakfast = 15;
const priceSchnaps = 25;

export class ReservationPageTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment(),
      endDate: moment(),
      focusedInput: null,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      numberOfPersons: 1,
      street: "",
      city: "",
      zipCode: "",
      country: "",
      notes: "",
      breakfast: false,
      schnaps: false,
      schnapsPersons: 1,
      termsAndConditions: false
    };
    this.form = null;
    moment.locale("de");
  }

  setFormRef = ref => {
    this.form = ref;
  };

  render() {
    const { page } = this.props;
    const {
      focusedInput,
      startDate,
      endDate,
      firstName,
      lastName,
      email,
      phone,
      numberOfPersons,
      street,
      city,
      zipCode,
      country,
      notes,
      breakfast,
      schnaps,
      schnapsPersons,
      termsAndConditions
    } = this.state;

    const nights =
      startDate && endDate
        ? endDate.startOf("day").diff(startDate.startOf("day"), "days")
        : 0;

    const priceForRoom = nights * pricePerNight;
    const priceForBreakfast = breakfast
      ? numberOfPersons * nights * priceBreakfast
      : 0;
    const priceForSchnaps = schnaps
      ? schnapsPersons * priceSchnaps
      : 0;

    const totalPrice = priceForRoom + priceForBreakfast + priceForSchnaps;
    const isFormValid = firstName && this.form && this.form.checkValidity();

    return (
      <article className="reservation">
        <div className="reservation-container container">
          <section className="reservation-header">
            <div className="reservation-titleWrapper">
              <h1 className="reservation-title">{page.frontmatter.title}</h1>
            </div>
          </section>
          <section className="reservation-body section">
            {page.bodyIsMarkdown ? (
              <ReactMarkdown
                className="reservation-description"
                source={page.html}
              />
            ) : (
              <HTMLContent
                className="reservation-description"
                content={page.html}
              />
            )}
          </section>
          <section className="reservation-form">
            <form
              id="reservation-form"
              ref={this.setFormRef}
              className="reservation_form"
              method="POST"
              action="/reservation.php"
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h3>Buchungsdaten</h3>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Aufenthaltsdauer</InputLabel>
                  <DateRangePicker
                    startDate={startDate}
                    startDateId="Anreise"
                    endDate={endDate}
                    endDateId="Abreise"
                    displayFormat="DD.MM.YYYY"
                    startDatePlaceholderText="Anreise"
                    endDatePlaceholderText="Abreise"
                    onDatesChange={({ startDate, endDate }) =>
                      this.setState({ startDate, endDate })
                    }
                    focusedInput={focusedInput}
                    onFocusChange={focusedInput =>
                      this.setState({ focusedInput })
                    }
                    minimumNights={2}
                    hideKeyboardShortcutsPanel={true}
                    withPortal={true}
                    numberOfMonths={1}
                    customArrowIcon="bis"
                    required={true}
                  />
                  <br />
                  <span className="info">
                    {nights > 1 ? `${nights} Nächte` : "Minimum 2 Nächte"}
                  </span>
                  <input type="hidden" name="Anzahl Nächte" value={nights} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="persons">Personen</InputLabel>
                  <Select
                    value={numberOfPersons}
                    onChange={e =>
                      this.setState({ numberOfPersons: e.target.value })
                    }
                    id="persons"
                    name="Personen"
                  >
                    {Array.from({ length: 6 }).map((_, idx) => {
                      return (
                        <MenuItem key={idx} value={idx + 1}>{`${idx +
                          1} Person${idx > 0 ? "en" : ""}`}</MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {nights > 1 ? (
                  <>
                    <Grid item xs={12}>
                      <h3>Kontaktdaten</h3>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="firstName"
                        label="Vorname"
                        name="Vorname"
                        value={firstName}
                        required
                        onChange={e =>
                          this.setState({ firstName: e.target.value })
                        }
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="lastName"
                        label="Nachname"
                        name="Nachname"
                        value={lastName}
                        required
                        onChange={e =>
                          this.setState({ lastName: e.target.value })
                        }
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="email"
                        label="Email"
                        type="email"
                        name="Email"
                        autoComplete="email"
                        value={email}
                        required
                        onChange={e => this.setState({ email: e.target.value })}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="telephone"
                        label="Telefonnummer"
                        name="Telefon"
                        value={phone}
                        required
                        onChange={e => this.setState({ phone: e.target.value })}
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="street"
                        label="Straße"
                        name="Straße"
                        value={street}
                        required
                        onChange={e =>
                          this.setState({ street: e.target.value })
                        }
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="zipCode"
                        label="Postleitzahl"
                        name="Postleitzahl"
                        value={zipCode}
                        required
                        onChange={e =>
                          this.setState({ zipCode: e.target.value })
                        }
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="city"
                        label="Ort"
                        name="Ort"
                        value={city}
                        required
                        onChange={e => this.setState({ city: e.target.value })}
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="country"
                        label="Land"
                        name="Land"
                        value={country}
                        required
                        onChange={e =>
                          this.setState({ country: e.target.value })
                        }
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="notes"
                        label="Nachricht an den Vermieter"
                        name="Nachricht"
                        value={notes}
                        multiline={true}
                        onChange={e => this.setState({ notes: e.target.value })}
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <h3>Zusatzleistungen</h3>
                    </Grid>

                    <Grid item xs={12}>
                      <div className="checkbox_with_text">
                        <Checkbox
                          checked={breakfast}
                          onChange={e =>
                            this.setState({ breakfast: e.target.checked })
                          }
                          id="breakfast"
                          name="Frühstück"
                          value="Ja"
                          inputProps={{ "aria-label": "Frühstück" }}
                        />
                        <span className="info">
                          Frühstück mit regionalen Köstlichkeiten (15€ pro
                          Person und Nacht)
                        </span>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <div className="checkbox_with_text">
                        <Checkbox
                          checked={schnaps}
                          onChange={e =>
                            this.setState({ schnaps: e.target.checked })
                          }
                          id="schnaps"
                          name="Schnappsverkostung"
                          value="Ja"
                          inputProps={{ "aria-label": "Schnappsverkostung" }}
                        />
                        <span className="info">
                          Hausgemachte Schnappsverkostung (25€ pro Person / ab
                          18 Jahren)
                        </span>
                      </div>
                    </Grid>
                    {schnaps ? (
                      <Grid item xs={12}>
                        <Select
                          value={schnapsPersons}
                          disabled={!schnaps}
                          onChange={e =>
                            this.setState({ schnapsPersons: e.target.value })
                          }
                          className="schnaps_persons"
                          name="Personen Schnappsverkostung"
                        >
                          {Array.from({ length: 6 }).map((_, idx) => {
                            return (
                              <MenuItem key={idx} value={idx + 1}>{`${idx +
                                1} Person${idx > 0 ? "en" : ""}`}</MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <h3>Preis</h3>
                      </Grid>
                      <Grid className="price-section" container spacing={3}>
                        <Grid item xs={6}>{`Übernachtungen:`}</Grid>
                        <Grid
                          item
                          xs={6}
                          className="price"
                        >{`${priceForRoom.toFixed(2)}€`}</Grid>
                        <Grid item xs={6}>{`Frühstück:`}</Grid>
                        <Grid
                          item
                          xs={6}
                          className="price"
                        >{`${priceForBreakfast.toFixed(2)}€`}</Grid>
                        <Grid item xs={6}>{`Schnappsverkostung:`}</Grid>
                        <Grid
                          item
                          xs={6}
                          className="price"
                        >{`${priceForSchnaps.toFixed(2)}€`}</Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={6}>
                          <b>{`Gesamtpreis:`}</b>
                        </Grid>
                        <Grid item xs={6} className="price">
                          <b>{`${totalPrice.toFixed(2)}€`}</b>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <div className="checkbox_with_text">
                        <Checkbox
                          checked={termsAndConditions}
                          onChange={e =>
                            this.setState({
                              termsAndConditions: e.target.checked
                            })
                          }
                          name="AGB und Datenschutz"
                          value="einverstanden"
                          required
                        />
                        <span className="info">
                          Ich erkläre mich mit den &nbsp;
                          <a target="_blank" href="/geschaeftsbedingungen">
                            AGB
                          </a>
                          &nbsp;und den &nbsp;
                          <a target="_blank" href="/datenschutz">
                            Datenschutzbestimmungen
                          </a>
                          &nbsp; einverstanden.
                        </span>
                      </div>
                    </Grid>

                    <Grid className="submit_wrapper" item xs={12}>
                      <input type="hidden" name="Miete" value={priceForRoom} />
                      <input
                        type="hidden"
                        name="Preis für Frühstück"
                        value={priceForBreakfast}
                      />
                      <input
                        type="hidden"
                        name="Preis für Schnapps"
                        value={priceForSchnaps}
                      />
                      <input
                        type="hidden"
                        name="Gesamtpreis"
                        value={totalPrice}
                      />

                      {isFormValid ? (
                        <input
                          className="submit_button"
                          type="submit"
                          value="Anfrage senden"
                        />
                      ) : (
                        <span className="info">
                          Bitte füllen Sie alle benötigten Formularfelder aus
                        </span>
                      )}
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </form>
          </section>
        </div>
      </article>
    );
  }
}

const ReservationPage = ({ data }) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription, browserTitle }
    }
  } = page;

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <ReservationPageTemplate page={{ ...page, bodyIsMarkdown: false }} />
    </Layout>
  );
};

ReservationPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ReservationPage;

export const ReservationPageQuery = graphql`
  query ReservationPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        seo {
          browserTitle
          title
          description
        }
      }
    }
    ...LayoutFragment
  }
`;
